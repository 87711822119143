import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/layout/seo"

import MainImg from "../../img/noLimitImg.jpg"

function Ai() {
  return (
    <Layout>
      <SEO title="M2MTech" />
      <div className="fobWrap bemsWrap">
        <p className="fobMainImg escalMainImg">
          <img src={MainImg} alt="" />
        </p>
        <div className="sectionWrap fobSectionWrap bemsOverview">
          <div className="textWrap">
            <h2>AI 서비스</h2>
            <p className="bodyText">
              <b>IoT 게이트웨이 수집 데이터 활용한 고장 예측</b>
              <br />
              -장애 부품 및 필요자재 예측 모델
              <br />
              -유사문제 해법 제시 모델
              <br />
              <br />
              <b>기능</b>
              <br />
              -(고장예측) 다기종(대기업/해외업체 및 중소기업) 승강기 컨 디션 및
              운행 상태를 실시간 모니터링, 데이터 기반 축적, 고장예 측시스템으로
              운영관리
              <br />
              -(분석지능) 부품수명주기관리를 통한 부품고장 예측하여 안전 하게
              승강기 유지/추척관리
              <br />
              -(무인물류배송) 무인배송 서비스 중 로봇 승하차 가능 승강기 연계
              시스템
              <br />
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Ai
